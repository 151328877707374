/* This CSS will applies when max-width is 480px */
@media screen and (max-width: 480px) {
    .navbar {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    top: 0;
    left: 0;
    right: 0;
    background-color: #4472ca;
}

.box-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.regis-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.profile-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    margin-top: 40px;
    justify-content: center;
}

.box {
    justify-content: flex-end;
    display: flex;
    gap: 20px;
    position: fixed;
    bottom: 7%;
}

.box-income-modal {
    display: flex;
    gap: 7px;
    margin-top: 20px;
}

.box-delete-scheduler {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}

.box-detail-scheduler {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
}

.profile-details {
    display: flex;
    margin: 10px 0;
}

.btn-group {
    width: 100px;
}

.btn-group-income-modal {
    width: 100%;
}

.btn-group-delete-scheduler {
    width: 25%;
}